import { DefaultEventsMap } from '@socket.io/component-emitter';
import { Socket, io } from 'socket.io-client';
import { dispatch } from 'store';
import { setSocketConnected } from 'store/reducers/socket';

let socketInstance: Socket<DefaultEventsMap, DefaultEventsMap>;

export const getSocketInstance = (token: string) => {
  if (!socketInstance) {
    socketInstance = io(process.env.REACT_APP_WS_API_URL || '', {
      transports: ['websocket'],
      query: { token }
    });
    dispatch(setSocketConnected(true));
  }
  return socketInstance;
};
