import { lazy } from 'react';

// project-imports
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - applications
const AppChat = Loadable(lazy(() => import('pages/apps/chat/chat')));
const AppCalendar = Loadable(lazy(() => import('pages/apps/calendar')));
const AppDashBoard = Loadable(lazy(() => import('pages/apps/dash-board')));
const AppAtendimento = Loadable(lazy(() => import('pages/apps/atendimento')));
const AppContatos = Loadable(lazy(() => import('pages/apps/contatos')));
const AppCanais = Loadable(lazy(() => import('pages/apps/canais')));
const AppDepartamentos = Loadable(lazy(() => import('pages/apps/departamentos')));
const AppGrupoContatos = Loadable(lazy(() => import('pages/apps/grupo-contatos')));
const AppCampanhas = Loadable(lazy(() => import('pages/apps/campanhas')));
const AppDashboardCampanhas = Loadable(lazy(() => import('pages/apps/dashboard-campanhas')));
const AppUsuario = Loadable(lazy(() => import('pages/apps/usuario')));
const AppTags = Loadable(lazy(() => import('pages/apps/tags')));

// ==============================|| MAIN ROUTES ||============================== //

const AppsRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'apps',
          children: [
            {
              path: 'chat',
              element: <AppChat />
            },
            {
              path: 'calendar',
              element: <AppCalendar />
            },
            {
              path: 'dashboard',
              element: <AppDashBoard />
            },
            {
              path: 'atendimento',
              element: <AppAtendimento />
            },
            {
              path: 'contatos',
              element: <AppContatos />
            },
            {
              path: 'canais',
              element: <AppCanais />
            },
            {
              path: 'departamento',
              element: <AppDepartamentos />
            },
            {
              path: 'grupo-contatos',
              element: <AppGrupoContatos />
            },
            {
              path: 'campanhas',
              element: <AppCampanhas />
            },
            {
              path: 'dashboard-campanhas',
              element: <AppDashboardCampanhas />
            },
            {
              path: 'usuario',
              element: <AppUsuario />
            },
            {
              path: 'tags',
              element: <AppTags />
            }
          ]
        }
      ]
    }
  ]
};

export default AppsRoutes;
