import WifiOffIcon from '@mui/icons-material/WifiOff';
import { Box } from '@mui/material';
import { getItemLocalStorage } from 'hooks/useLocalStorage';
import { useMemo, useState } from 'react';
import CustomDialog from 'sections/components-overview/dialogs/CustomDialog';
import { useSelector } from 'store';

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

const DisconnectedModal = () => {
  const [open, setOpen] = useState(false);
  const socketState = useSelector((state) => state.socket);
  const accessToken = getItemLocalStorage('accessToken');

  const handleReconnect = () => {
    window.location.reload();
  };

  useMemo(() => {
    if (!socketState.connected && !!accessToken) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketState]);

  return (
    <Box zIndex={'10000'}>
      <CustomDialog
        openDialog={open}
        title="Sua conexão foi perdida"
        showToggleButton
        description=""
        showSuccessButton
        onSuccessCallback={handleReconnect}
        successButtonText="Reconectar"
        showCloseButton={false}
        children={
          <Box
            width={'100%'}
            textAlign={'center'}
          >
            <WifiOffIcon
              color="error"
              sx={{ fontSize: '10rem' }}
            />
          </Box>
        }
      />
    </Box>
  );
};

export default DisconnectedModal;
