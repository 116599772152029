import { createSlice } from '@reduxjs/toolkit';

// types
import { SocketProps } from 'types/socket';

// initial state
export const initialStateSocket: SocketProps = {
  connected: false
};

// ==============================|| SLICE - MENU ||============================== //

const socket = createSlice({
  name: 'socket',
  initialState: initialStateSocket,
  reducers: {
    setSocketConnected(state, action) {
      state.connected = action.payload;
    }
  }
});

export default socket.reducer;

export const { setSocketConnected } = socket.actions;
