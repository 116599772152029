import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// types
import { AuthProps } from 'types/auth';

// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// ==============================|| SLICE - AUTH ||============================== //

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setRegister(state, action: PayloadAction<{ user: AuthProps['user'] }>) {
      state.user = action.payload.user;
    },
    setLogin(state, action: PayloadAction<{ user: AuthProps['user'] }>) {
      state.isLoggedIn = true;
      state.isInitialized = true;
      state.user = action.payload.user;
    },
    setLogout(state) {
      state.isInitialized = true;
      state.isLoggedIn = false;
      state.user = null;
    }
  }
});

export default auth.reducer;

export const { setRegister, setLogin, setLogout } = auth.actions;
