// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  KyberNetwork,
  Messages2,
  Calendar1,
  Kanban,
  Profile2User,
  Bill,
  UserSquare,
  ShoppingBag,
  Chart21,
  Graph,
  Dash,
  HomeTrendUp,
  Headphone,
  Personalcard,
  People,
  Flag,
  Activity,
  User,
  Tag2
} from 'iconsax-react';

import PieChartIcon from '@mui/icons-material/PieChart';
// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  applications: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  charts: Chart21,
  chart: Graph,
  dash: Dash,
  pieChart: PieChartIcon,
  chartdash: HomeTrendUp,
  channels: Headphone,
  department: Personalcard,
  contactgroup: People,
  campaigns: Flag,
  campaignsdashboard: Activity,
  user: User,
  tags: Tag2
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications: NavItemType[] = [
  {
    id: 'group-applications',
    icon: icons.applications,
    type: 'group',
    children: [
      {
        id: 'chat',
        title: <FormattedMessage id="Chat" />,
        type: 'item',
        url: '/apps/chat',
        icon: icons.chat,
        breadcrumbs: false
      },
      {
        id: 'calendar',
        title: <FormattedMessage id="Calendar" />,
        type: 'item',
        url: '/apps/calendar',
        icon: icons.calendar,
        breadcrumbs: false
      },
      {
        id: 'dashboard',
        title: <FormattedMessage id="Dashboard" />,
        type: 'item',
        url: '/apps/dashboard',
        icon: icons.pieChart,
        breadcrumbs: false
      },
      {
        id: 'atendimento',
        title: <FormattedMessage id="Dashboard Atendimentos" />,
        type: 'item',
        url: '/apps/atendimento',
        icon: icons.chartdash,
        breadcrumbs: false
      },
      {
        id: 'contatos',
        title: <FormattedMessage id="Contatos" />,
        type: 'item',
        url: '/apps/contatos',
        icon: icons.profile,
        breadcrumbs: false
      },
      {
        id: 'canais',
        title: <FormattedMessage id="Canais" />,
        type: 'item',
        url: '/apps/canais',
        icon: icons.channels,
        breadcrumbs: false
      },
      {
        id: 'departamento',
        title: <FormattedMessage id="Departamento" />,
        type: 'item',
        url: '/apps/departamento',
        icon: icons.department,
        breadcrumbs: false
      },
      {
        id: 'grupocontatos',
        title: <FormattedMessage id="Grupo Contatos" />,
        type: 'item',
        url: '/apps/grupo-contatos',
        icon: icons.contactgroup,
        breadcrumbs: false
      },
      {
        id: 'campanhas',
        title: <FormattedMessage id="Campanhas" />,
        type: 'item',
        url: '/apps/campanhas',
        icon: icons.campaigns,
        breadcrumbs: false
      },
      {
        id: 'dashboardcampanhas',
        title: <FormattedMessage id="Dashboard Campanhas" />,
        type: 'item',
        url: '/apps/dashboard-campanhas',
        icon: icons.campaignsdashboard,
        breadcrumbs: false
      },
      {
        id: 'usario',
        title: <FormattedMessage id="Usuário" />,
        type: 'item',
        url: '/apps/usuario',
        icon: icons.user,
        breadcrumbs: false
      },
      {
        id: 'tags',
        title: <FormattedMessage id="Tags" />,
        type: 'item',
        url: '/apps/tags',
        icon: icons.tags,
        breadcrumbs: false
      }
    ]
  },
  {
    id: 'group-charts-map',
    icon: icons.charts,
    type: 'group',
    children: [
      {
        id: 'react-chart',
        title: <FormattedMessage id="charts" />,
        type: 'collapse',
        icon: icons.chart,
        children: [
          {
            id: 'apexchart',
            title: <FormattedMessage id="apexchart" />,
            type: 'item',
            url: '/charts/apexchart'
          },
          {
            id: 'org-chart',
            title: <FormattedMessage id="org-chart" />,
            type: 'item',
            url: '/charts/org-chart'
          }
        ]
      }
    ]
  }
];

export default applications;
