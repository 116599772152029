import axios from 'axios';
import { isTokenValid } from 'contexts/JWTContext';
import { getItemLocalStorage } from 'hooks/useLocalStorage';
import { enqueueSnackbar } from 'notistack';

const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3010/' });
const accessToken = getItemLocalStorage('accessToken');

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      !window.location.href.includes('/login') &&
      !error.config.baseURL.includes('mock-data-api')
    ) {
      window.location.reload();
    }
    if (error.response && error.response.status === 400) {
      if (error.response.data?.description) {
        enqueueSnackbar(error.response.data.description, { variant: 'error', autoHideDuration: 3000 });
      }
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

axiosServices.interceptors.request.use(
  (config) => {
    if (isTokenValid(accessToken)) {
      config.headers['X-Access-Token'] = accessToken;
      // config.headers['Authorization'] =
      //   'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1ZTg3ODA5MjczZTI4Yjk2ZDJlMzg1MzgiLCJpYXQiOjE3MTQ4MjE1NzcsImV4cCI6MTcxNDkwNzk3N30.AwuTohZxp9qgjnRynyVD9f7sJUiPl0uUYT7_I3grqZg';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosServices;
