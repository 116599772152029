// project-imports
import applications from './applications';

// types
import { NavItemType } from 'types/menu';

const menuItems: { items: NavItemType[] } = {
  // items: [widget, applications, formsTables, chartsMap, pages, support]
  items: applications
};

export default menuItems;
