import { ReactNode } from 'react';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// project-imports
import IconButton from 'components/@extended/IconButton';

// assets
import { PopupTransition } from 'components/@extended/Transitions';
import { Add } from 'iconsax-react';

// ==============================|| CUSTOMIZED - CONTENT ||============================== //

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(3)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1.25),
    paddingRight: theme.spacing(2),
    minWidth: 500
  }
}));

// ==============================|| CUSTOMIZED - TITLE ||============================== //

export interface DialogTitleProps {
  id: string;
  children?: ReactNode;
  showCloseButton?: boolean;
  onClose?: () => void;
}

interface CustomDialogProps {
  openDialog: boolean;
  setOpenDialog?: (open: boolean) => void;
  showToggleButton?: boolean;
  title?: string;
  description?: string;
  children?: ReactNode;
  onCloseCallback?: () => void;
  onSuccessCallback?: () => void;
  closeButtonText?: string;
  successButtonText?: string;
  showSuccessButton?: boolean;
  showCloseButton?: boolean;
}

export const BootstrapDialogTitle = ({ children, onClose, showCloseButton, ...other }: DialogTitleProps) => (
  <DialogTitle
    sx={{ m: 0, p: 2 }}
    {...other}
  >
    {children}
    {onClose && showCloseButton ? (
      <IconButton
        aria-label="close"
        onClick={onClose}
        color="secondary"
        sx={{
          position: 'absolute',
          right: 10,
          top: 10
        }}
      >
        <Add style={{ transform: 'rotate(45deg)' }} />
      </IconButton>
    ) : null}
  </DialogTitle>
);

const mockedDescription =
  'Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.';

// ==============================|| DIALOG - CUSTOMIZED ||============================== //

export default function CustomDialog({
  openDialog,
  showToggleButton,
  title = 'Título',
  description = mockedDescription,
  children,
  onCloseCallback,
  onSuccessCallback,
  closeButtonText,
  successButtonText,
  showSuccessButton,
  showCloseButton,
  setOpenDialog
}: CustomDialogProps) {
  const handleClickOpen = () => {
    setOpenDialog && setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog && setOpenDialog(false);
  };
  return (
    <>
      <BootstrapDialog
        onClose={() => {
          handleClose();
          onCloseCallback && onCloseCallback();
        }}
        TransitionComponent={PopupTransition}
        keepMounted
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        {title ? (
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={() => {
              handleClose();
              onCloseCallback && onCloseCallback();
            }}
            showCloseButton={showCloseButton}
          >
            {title}
          </BootstrapDialogTitle>
        ) : null}
        {description || children ? (
          <DialogContent
            dividers
            sx={{ p: 3 }}
          >
            {description ? (
              <Typography
                variant="h6"
                gutterBottom
              >
                {description}
              </Typography>
            ) : null}
            {children}
          </DialogContent>
        ) : null}
        {showSuccessButton || showCloseButton ? (
          <DialogActions>
            {showCloseButton ? (
              <Button
                color="error"
                onClick={() => {
                  handleClose();
                  onCloseCallback && onCloseCallback();
                }}
              >
                {closeButtonText ?? 'Disagree'}
              </Button>
            ) : null}
            {showSuccessButton ? (
              <Button
                variant="contained"
                onClick={() => {
                  handleClickOpen();
                  onSuccessCallback && onSuccessCallback();
                }}
              >
                {successButtonText ?? 'Agree'}
              </Button>
            ) : null}
          </DialogActions>
        ) : null}
      </BootstrapDialog>
    </>
  );
}
